
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const mainRules = {
    roleName: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    roleCode: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    roleDesc: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    dsType: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
}
export default{
    mainRules
}